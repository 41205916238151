<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <div class="f_logo">
          <img src="../img/logo.png" alt="">
          <p class="address">UNIT 1406A, 14/F, THE BELGIAN BANK BUILDING, NOS. 721 - 725 NATHAN ROAD, KOWLOON, HONG KONG</p>
          </div>
        <div class="r">
          <Nav class="rnav" :hrefList="hrefList"></Nav>
        </div>
      </div>
      <div class="footerinfo">
       <p>© 2023 AGI INNOVATIONS LIMITED All Rights Reserved.</p>
       <!-- <p>© 2022 Lewei Entertainment Company All Rights Reserved.</p> -->
      </div>


    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  data() {
    return {
      hrefList: ['About Us', 'Products', 'Contact Us']
    }
  },
  components: {
    Nav
  },
}
</script>

<style lang="scss" scoped src="./footer.scss">

</style>