<template>
  <div class="header_nav ">
    <div class="wrap">
      <div class="l" @click="gotohome">
        <img class="logo" src="../img/logo.png" />
      </div>
      <div class="r">
        <Nav class="rnav" :hrefList="hrefList"></Nav>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  data() {
    return {
      hrefList: ['About Us', 'Products', 'Contact Us']
    }
  },
  components: {
    Nav
  },
  methods: {
    gotohome() {
      console.log('home');
      this.$router.push('/')
    },
    changebg() {
      let sh = document.documentElement.scrollTop
      if (sh >= 50) {
        document.getElementsByClassName('header_nav')[0].classList.add('navbg')
      }
      if (sh < 50) {
        document.getElementsByClassName('header_nav')[0].classList.remove('navbg')
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./headernav.scss"></style>