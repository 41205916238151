import cryptojs from "crypto-js";
import axios from 'axios'
import MD5 from 'js-md5';

class Global {
    static DEBUG = true;
    static DEFAULT_LANGUAGE = 'ar';
    static LANGUAGE = Global.DEFAULT_LANGUAGE;
    static OSS_DOMAIN = Global.DEBUG ? '//video-qa.lavideo.vip' : '//video.hayyaapp.com';
    static API_DOMAIN = Global.DEBUG ? '//api-qa.girlsmm.com' : '//api.girlsmm.com';

    static LOGIN_APPID = Global.DEBUG ? 'bff59158-edc6-4beb-b183-3bf6631e8d0a' : 'f614034c-c7a2-486c-a87b-19c88fa635d3';

    static SIGN_APPKEY = Global.DEBUG ? 'hayya-eu' : 'hayya-eu';
    static SING_APPSECRET = Global.DEBUG ? 'a5aa7d38-34e6-4115-bdf5-f4ea0c98ad66' : 'a5aa7d38-34e6-4115-bdf5-f4ea0c98ad66';

    static LOGIN_TOKEN = '';
    static LOGIN_USERID = '';
    static APP_VERSIOIN = '20000001';
    static LOGIN_PRODID = Global.DEBUG ? 302 : 302;
    static bridge;
    static PLATFORM_IOS = false;
    static activitiesGiftId = 307; //活动礼物ID
    static activitiesTypeId = 4; //活动类型

    static ID_TABS0 = 0;
    static ID_TABS1 = 1;
    static WAFA_APPSLIST = ['6a9e8345-7b7c-4255-9b23-1fba52de11c8',
        'bff59158-edc6-4beb-b183-3bf6631e8d0a',
        '6a9e8345-7b7c-4255-9b23-1fba5abcdefg',
        'f614034c-c7a2-486c-a87b-19c88fa635d3']

    static onLoginInit = function (props) {
        // console.log('onLoginInit');
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        Global.PLATFORM_IOS = isiOS;

        const myAppId = this.getUrlParams('appId');
        if (myAppId !== null && myAppId !== undefined && myAppId !== '') {
            Global.LOGIN_APPID = myAppId;
        }

        const myUserId = this.getUrlParams('userId');
        if (myUserId !== null && myUserId !== undefined && myUserId !== '') {
            Global.LOGIN_USERID = myUserId;
        }
        // Global.LOGIN_USERID = (this.$route.query.userId == undefined) ? '0' : this.$route.query.userId;

        // console.log('userid:  ',this.$route.query.userId);

        // 不通过query获取的原因是，token未进行url编码，导致token含有加号时，获取到的token加号被空格替换，最终token校验失败
        const mytoken = this.getUrlParams('token');
        Global.LOGIN_TOKEN = (mytoken == null) ? '' : mytoken;

        console.log('Global.LOGIN_TOKEN------>', mytoken);

        const language = this.getUrlParams('lang');
        Global.LANGUAGE = language;
        Global.checkAswDomain();

    }
    static checkAswDomain() {
        console.log();
        const pageUrl = window.location.href;
        // console.log('axios:', axios.defaults.baseURL);
        if (pageUrl === null || pageUrl === undefined || pageUrl === '') {
            return;
        }
        if (pageUrl.search('hayyamm.com') >= 0) {
            Global.API_DOMAIN = Global.DEBUG ? '//api-qa.hayyamm.com' : '//api.hayyamm.com';

            axios.defaults.baseURL = Global.API_DOMAIN;
        }
    }
    /**
     * 跳转至指定的路由页面。
     */
    static navigateToPage = function (_route = '', $data = {}) {
        if (_route === undefined || _route === null) {
            return;
        }
        if (_route === '') {
            return;
        }
        const timestamp = Date.parse(new Date());
        const params = {
            token: Global.LOGIN_TOKEN,
            userId: Global.LOGIN_USERID,
            appId: Global.LOGIN_APPID,
            lang: Global.LANGUAGE,
            t: timestamp,
            ...$data
        }

        console.log('跳转至指定的路由页面--params-->', params);

        router.push({
            pathname: _route,
            query: { ...params }
        });
    }

    //获取url参数
    static getUrlParams = function (par) {
        if (par == '')
            return null;
        var reg = new RegExp("(^|&)" + par + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        // console.log(par + ':' + r)
        if (r != null)
            return r[2];

        return null;
    }

    static handleLoginOut = function () {

        console.log("handOut")
        //IOS
        if (Global.bridge) {
            var params = { code: '30010205' };
            Global.bridge.callHandler('JTN_DATA', params, function (resp) {
                console.log('Global-handleLoginOut--->', resp);
            });
        }

        //Android
        if (window.mobilejs) {
            var params = { code: '30010205' };
            window.mobilejs.handleLoginOut();
        }
    }
    static CHECKNULL = function (obj) {
        if (obj === null || obj === undefined) { return true }
        else return false

    }

    //VM 打开用户详情页
    static goPersonalActivity = function ($uid, $userId, $appId) {
        console.log('Global.打开用户详情页------>', $appId, $userId);
        if (this.WAFA_APPSLIST.includes($appId) === true) {
            this.handleUserDetail($userId, $uid);
            return;
        }
        const mobilejs = window['mobilejs'];
        if (Global.CHECKNULL(mobilejs) === true) {
            return;
        }
        if (Global.CHECKNULL(mobilejs.goPersonalActivity) === true) {
            return;
        }
        mobilejs.goPersonalActivity($userId, $appId)
    }
    //APP --> 打开用户详情页
    static handleUserDetail = function ($userId, $uid) {

        console.log('userid:', $userId, 'uid', $uid)
        if (($userId == null) || ($userId == undefined) || ($uid == null) || ($uid == undefined)) {
            return;
        }
        //IOS
        if (Global.bridge) {
            var params = { uid: $uid, userId: `${$userId}` };
            Global.bridge.callHandler('JTN_USER_DETAIL', params, function (resp) {
                console.log('Global-handleUserDetail--->', resp);
            });
        }

        //Android
        if (window.mobilejs) {
            var params = { uid: $uid, userId: `${$userId}` };
            window.mobilejs.handleUserDetail(JSON.stringify(params));
        }
    }
    //APP --> 打开房间页
    static handleRoomDetail = function (roomId) {
        console.log(roomId)
        //IOS
        if (Global.bridge) {
            var params = { roomId: roomId }
        }
        //Android
        if (window.mobilejs) {
            var parmas = { roomId: roomId }
            window.mobilejs.handleJumpRoom(JSON.stringify(parmas));
        }
    }
    static openRoomGift = function (roomId, openGiftPop) {
        // Android
        console.log('rommmmmmmmmmm');
        if (window.mobilejs) {
            var parmas = { roomId: roomId }
            window.mobilejs.openRoomGift(JSON.stringify(parmas))
        }
    }
    //根据参数列表生成接口签名
    static getReqAppSign = (params) => {
        if (params === undefined || params === '') {
            return '';
        }
        const sortedData = Global.ksort(params);
        if (sortedData === undefined || sortedData === '') {
            return '';
        }
        console.log('APP签名-参数列表-->', sortedData);
        let keylist = Object.keys(sortedData);
        let myvalue = '';
        let mySignStr = '';
        keylist.forEach((key) => {
            myvalue = sortedData[key];
            mySignStr = mySignStr.concat(`${key}${myvalue}`);
        })
        const md5SignStr = mySignStr.concat(`${Global.SING_APPSECRET}`);
        console.log('APP签名字符串-->', md5SignStr, cryptojs);

        // const MD5 = cryptojs.createHash('md5');
        const appSign = cryptojs.MD5(md5SignStr).toString();//MD5.update(md5SignStr).digest('hex');
        const myAppSign = appSign.toLocaleUpperCase();
        console.log('APP签名生成签名-->', myAppSign);

        return myAppSign;
    }

    static ksort = function (params) {
        let sorted = {};
        let keys = Object.keys(params);
        keys.sort();
        keys.forEach((key) => {
            sorted[key] = params[key];
        })
        return sorted;
    }
    //关闭窗口
    static closeWindow() {
        window['mobilejs'].close();
    }
    //阻止页面滚动
    static preventBack(flag) {
        console.log(flag);
        if (flag) {
            const top =
                document.documentElement.scrollTop || document.body.scrollTop;
            document.body.style.cssText += `position: fixed;width: 100vw;left: 0;top: ${-top}px`;
        } else {
            const top = document.body.style.top;
            document.body.style.cssText += `
                  position: static;
              `;
            window.scrollTo(0, Math.abs(parseFloat(top)));
        }
    }

    static async deleteAccountWeb($appId, $accountId, $password) {
        return new Promise((resolve, reject) => {
            const params = {
                baseParams: {
                    appId: $appId,
                    lang: 'en'
                },
                business: {
                    appId: $appId,
                    accountId: $accountId,
                    password: $password,
                    userId: '76548989009776545678'
                },
                userAuth: {
                    appId: $appId,
                    userId: '76548989009776545678',
                    token: 'absdYHIUY*76658765yugy8765'
                }
            }
            let request = new XMLHttpRequest();
            const signParams = {
                module: `user`,
                method: `deleteAccountWeb`,
                timestamp: (new Date()).valueOf(),
                rpcType: 'dubbo',
                content: JSON.stringify(params)
            }

            const sortedData = {};
            let keys = Object.keys(signParams);
            keys.sort();
            keys.forEach((key) => {
                sortedData[key] = params[key];
            });

            let keylist = Object.keys(signParams);
            let myValues = '';
            let mySignStr = '';
            keylist.forEach((key) => {
                myValues = sortedData[key];
                mySignStr = mySignStr.concat(`${key}${myValues}`);
            });
            const md5SignStr = mySignStr.concat(`a5aa7d38-34e6-4115-bdf5-f4ea0c98ad66`);
            const appSign = `${MD5(md5SignStr)}`;
            const myAppSign = appSign.toLocaleUpperCase();

            let apiHost = 'https://api.baatlive.com';
            if ($appId === 'ef96349e-dcd0-4e2a-8361-62c2b881047b') {
                apiHost = 'https://api.baatlivelive.com';
            }

            request.open("POST", apiHost, true);
            request.setRequestHeader('Content-Type', 'application/json');
            request.setRequestHeader('module', signParams.module);
            request.setRequestHeader('appId', `v.u.deleteAccountWeb`);
            request.setRequestHeader('appVersion', '1');
            request.setRequestHeader('appkey', 'hayya-eu');
            request.setRequestHeader('method', signParams.method);
            request.setRequestHeader('rpcType', signParams.rpcType);
            request.setRequestHeader('sign', myAppSign);
            request.setRequestHeader('timestamp', `${signParams.timestamp}`);

            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    let dataJson = null;
                    try {
                        dataJson = JSON.parse(response);
                    } catch (e) {
                        reject({ code: -1, message: 'The data parse error' });
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }


    //接口调用
    static async axiosHttpRequest(param, module, method, appId) {
        const params = {
            baseParams: {
                appId: Global.LOGIN_APPID,
                lang: Global.LANGUAGE,
            },
            business: param,
            userAuth: {
                appId: Global.LOGIN_APPID,
                userId: Global.LOGIN_USERID,
                token: Global.LOGIN_TOKEN,
            },
        };
        //签名
        const signParams = {
            module: module,
            method: method,
            timestamp: Date.parse(new Date()),
            rpcType: "dubbo",
            content: JSON.stringify(params),
        };
        //签名算法
        const mySign = Global.getReqAppSign(signParams);

        return axios({
            method: "post",
            url: "",
            headers: {
                "Content-Type": "application/json",
                module: signParams.module,
                appId: appId,
                appVersion: 1,
                appkey: Global.SIGN_APPKEY,
                method: signParams.method,
                rpcType: signParams.rpcType,
                sign: mySign,
                timestamp: signParams.timestamp,
            },
            data: params,
        })
    }
    //判断是否在活动期内
    static inDate(time1, time2) {
        var time = Date.parse(new Date());
        var date1 = Date.parse(new Date(time1.replace(/-/g, "/")));
        var date2 = Date.parse(new Date(time2.replace(/-/g, "/")));
        //在活动期内
        if (date1 < time && date2 > time) {
            return 0;
        }
        //在活动期间之前
        if (date1 > time && date2 > time) {
            return -1;
        }
        //活动结束
        return 1;
    }
    //获取倒计时时间
    static showtime(endTime, myTimerId) {
        var nowtime = Global.getZoneTime(5.5) //获取当前时间
        //console.log("nowtime", nowtime);
        var endtime = new Date(endTime); //定义结束时间 2020/11/26 23:59

        var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
            leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
            lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
            leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
            lefts = Math.floor((lefttime / 1000) % 60); //计算秒数

        lefth = lefth < 10 ? `0${lefth}` : lefth;
        leftm = leftm < 10 ? `0${leftm}` : leftm;
        lefts = lefts < 10 ? `0${lefts}` : lefts;

        if (lefttime <= 0) {
            clearInterval(myTimerId);
            return "Ended";
        }

        if (leftd <= 0) {
            //console.log(lefth + ":" + leftm + ":" + lefts);
            return lefth + ":" + leftm + ":" + lefts;
            console.log(lefth + ":" + leftm + ":" + lefts);
        }

        return leftd + "DAY " + lefth + ":" + leftm + ":" + lefts; //返回倒计时的字符串
    }
    // 北京是getZoneTime(8),纽约是getZoneTime(-5),班加罗尔是getZoneTime(5.5). 偏移值是本时区相对于格林尼治所在时区的时区差值
    static getZoneTime(offset) {
        // 取本地时间
        var localtime = new Date();

        // 取本地毫秒数
        var localmesc = localtime.getTime();
        // 取本地时区与格林尼治所在时区的偏差毫秒数
        var localOffset = localtime.getTimezoneOffset() * 60000;
        // 反推得到格林尼治时间
        var utc = localOffset + localmesc;
        // 得到指定时区时间
        var calctime = utc + (3600000 * offset);

        var nd = new Date(calctime);
        return nd;
        //return nd.toJSON().replace("T", " ").replace("Z", " ").replace(".", " ");
        //return nd.getFullYear()+"-"+nd.getUTCMonth()+"-"+nd.getDay() + " " + nd.toLocaleTimeString() ;
    }
    //根据传入的时间戳计算倒计时
    static showEndTime(lefttime, myTimerId) {
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24))//计算天数
        let lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24)//计算小时数
        let leftm = Math.floor((lefttime / (1000 * 60)) % 60) //计算分钟数
        let lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        lefth = lefth < 10 ? `0${lefth}` : lefth;
        leftm = leftm < 10 ? `0${leftm}` : leftm;
        lefts = lefts < 10 ? `0${lefts}` : lefts;
        if (lefttime <= 0) {
            //clearInterval(myTimerId);
            return "Ended";
        }
        if (leftd <= 0) {
            // console.log(lefth + ":" + leftm + ":" + lefts);
            return lefth + ":" + leftm + ":" + lefts;
        }
        return leftd + "Day " + lefth + ":" + leftm + ":" + lefts; //返回倒计时的字符串
    }
    static showdayEndTime(lefttime, myTimerId) {
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24))//计算天数
        let lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24)//计算小时数
        let leftm = Math.floor((lefttime / (1000 * 60)) % 60) //计算分钟数
        let lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        lefth = lefth < 10 ? `0${lefth}` : lefth;
        leftm = leftm < 10 ? `0${leftm}` : leftm;
        lefts = lefts < 10 ? `0${lefts}` : lefts;
        if (lefttime <= 0) {
            //clearInterval(myTimerId);
            return "Ended";
        }
        if (leftd <= 0) {
            // console.log(lefth + ":" + leftm + ":" + lefts);
            return leftd + ` Day ` + lefth + ":" + leftm + ":" + lefts;
        }
        return leftd + "Day " + lefth + ":" + leftm + ":" + lefts; //返回倒计时的字符串
    }





}

export default Global;

