<template>
  <div class="home" @mousewheel="chslide">
    <Headernav ref="headernav"></Headernav>

    <el-dialog :before-close="() => { handleClose('DialogDelTip') }" title="" v-model="DialogDelTip" width="40%"
      style="border-radius: 12px;" center>
      <div class="tipcontent">
        <img src="./img/icon_tip.png" alt="">
        <p class="tiptitle">Are you sure to delete the account?</p>
        <p class="tiptext">1.Account-related information will be deleted, and friends/followers/chat records will be
          deleted.</p>
        <p class="tiptext">2.Your rank level/account balance/All personal equity will be cleared.</p>
        <p class="tiptext">3.After the account is deleted, it can not be recovered, and you can no longer log in to this
          account.</p>
      </div>
      <template #footer>
        <div class="nextbtn" @click="NextStep">Next</div>
      </template>
    </el-dialog>

    <el-dialog :before-close="() => { handleClose('DialogDelInfo') }" title="" v-model="DialogDelInfo" width="40%"
      style="border-radius: 12px;" center>
      <div class="tipcontent">
        <p style="margin-bottom:24px" class="tiptitle">Verify your account</p>
        <el-form style="width:80%" :label-position="labelPosition" :model="ruleForm" :rules="rules" ref="ruleForm"
          label-width="100px" class="demo-ruleForm">
          <el-form-item label="Account ID" prop="account">
            <el-input v-model="ruleForm.account"></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialogfooter">
          <div class="nextbtn comf" @click="deleteaccount">Delete account</div>
          <div class="nextbtn cancle" @click="() => { handleClose('DialogDelInfo') }">Cancle</div>
        </div>
      </template>
    </el-dialog>

    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Headernav from '@/components/Headernav/Headernav.vue'
import Content from '@/components/Content/Content.vue'
import Footer from '@/components/Footer/Footer.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    Content,
    Footer,
    Headernav
  },
  computed: {
    ...mapState(['DialogDelTip', 'DialogDelInfo']),
  },
  data() {
    return {
      ruleForm: {
        account: '',
        password: ''
      },
      rules: {
        account: [
          { required: true, trigger: 'blur' },
          { min: 3, trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    ...mapMutations(['setDialogState']),
    ...mapActions(['deleteAccountsHandle']),
    deleteaccount() {
      let userid = this.ruleForm.account
      let password = this.ruleForm.password
      console.log(userid, password);
      this.deleteAccountsHandle({userid, password})
    },
    handleClose(data) {
      this.setDialogState({ setstate: data, stateinfo: false })
    },
    NextStep() {
      this.setDialogState({ setstate: 'DialogDelTip', stateinfo: false })
      this.setDialogState({ setstate: 'DialogDelInfo', stateinfo: true })
    },
    chslide() {
      this.$refs.headernav.changebg()
    }
  }


}
</script>
<style lang="scss" scoped>
.bg {
  width: 100%;
  position: sticky;
  top: 0;
}

.nextbtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #f66;
  color: #fff;
  border-radius: 33px;
  width: 156px;
  height: 44px;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
}

.tipcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
  }

  .tiptitle {
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
  }

  .tiptext {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    color: #475566;
    text-align: left;
    margin: 12px 0;
    font-weight: 400;
  }
}

.dialogfooter {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.comf {
  background: #fff;
  border: 1px solid #f66;
  color: #f66;
}
</style>
