import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/css/global.scss'
import './assets/css/font.css'
import animated from 'animate.css'
import wow from 'wowjs'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



const app = createApp(App).use(store).use(router)
app.mount('#app')
app.use(ElementPlus)
app.config.globalProperties.$message = ElementPlus.Message;

app.use(animated)
app.config.globalProperties.$wow = wow
